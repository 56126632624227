import '@reach/menu-button/styles.css';
import '~/styles/global.css';

import { ApolloProvider } from '@apollo/client';
import { useFocusVisible } from '@react-aria/interactions';
import { OverlayProvider } from '@react-aria/overlays';
import { ErrorBoundary, init } from '@sentry/react';
import { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useState } from 'react';
import { Provider as ReakitProvider } from 'reakit';
import { ThemeProvider } from 'styled-components';
import { SEO } from '~/components/seo';
import { env } from '~/config/env';
import { AuthProvider } from '~/context/auth';
import { ToastsProvider } from '~/context/toasts';
import { useApollo } from '~/hooks/use-apollo';
import { useFathomAnalytics } from '~/hooks/use-fathom-analytics';
import { DialogsProvider } from '~/system/dialogs/dialog';

import type { GraphQLError } from 'graphql'
const NotFound = dynamic(() => import('~/pages/not-found'))
// const Intercom = dynamic(() => import('~/components/intercom'), { ssr: false })
const LiveSession = dynamic(() => import('~/components/live-session'), {
  ssr: false,
})

const theme = {
  space: [],
  breakpoints: ['640px', '768px', '1024px'],
  mediaQueries: {
    small: `@media screen and (min-width: 640px)`,
    medium: `@media screen and (min-width: 768px)`,
    large: `@media screen and (min-width: 1024px)`,
  },
}

init({
  dsn: env.sentry.dsn,
  release: env.sentry.release,
  enabled: process.env.NODE_ENV === 'production',
})

const App = ({ Component, pageProps, router }: AppProps) => {
  const [error, setError] = useState<GraphQLError>()
  const apolloClient = useApollo(setError)
  useFathomAnalytics()
  useFocusVisible()

  // @ts-ignore
  let getLayout = Component.getLayout || ((page: JSX.Element) => page)

  if (error?.extensions?.type === 'not_found') {
    return <NotFound />
  }

  return (
    <>
      <Head>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
        <meta name="theme-color" content="#5653ff" />
        <link
          rel="preload"
          as="font"
          href="/fonts/graphik-regular.woff2"
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          as="font"
          href="/fonts/graphik-medium.woff2"
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          as="font"
          href="/fonts/graphik-bold.woff2"
          type="font/woff2"
          crossOrigin="anonymous"
        />
      </Head>
      <SEO
        title="Kindest"
        description="Kindest uses smart automations, robust CRM and beautiful fundraising websites to help nonprofits like you raise more money."
        hideKindest
      />
      <ErrorBoundary>
        <ApolloProvider client={apolloClient}>
          <OverlayProvider>
            <ThemeProvider theme={theme}>
              <ReakitProvider>
                <AuthProvider>
                  <ToastsProvider>
                    {getLayout(<Component {...pageProps} />)}
                    {router.pathname.startsWith('/sign-in') && <LiveSession />}
                    {router.pathname.startsWith('/sign-up') && <LiveSession />}
                    <DialogsProvider />
                  </ToastsProvider>
                </AuthProvider>
              </ReakitProvider>
            </ThemeProvider>
          </OverlayProvider>
        </ApolloProvider>
      </ErrorBoundary>
    </>
  )
}

export default App
